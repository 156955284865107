import './ErrorPage.css'

const ErrorPage = () => {
    return (
        <section className='error-page'>
            <h1><span style={{color: '#fcd535'}}>404</span> <br />Page Not Found!</h1>
        </section>
    );
}
 
export default ErrorPage;